<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DE REGISTRO DE INCIDENTES PELIGROSOS E INCIDENTES:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaIncidente.length}}</span>
                            <br>
                            <span class="text-muted">Incidentes peligrosos e incidentes registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de indicentes peligrosos e incidentes</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarIncidente = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo registro</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Incidentes peligrosos e incidentes registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaIncidente" :fields="camposIncidente" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarIncidente(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarIncidente(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(idProyecto)="param">
                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :show.sync="modalRegistrarIncidente" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo Incidente</span>
            </h6>
            <CButtonClose @click="modalRegistrarIncidente = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarIncidente)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Empleador Principal" active>
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                            <b-form-group label="Proyecto:" class="mb-2">
                                                <v-select @input="obtenerDiasProyecto" :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIncidente.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIncidente.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="días de itinerario" rules="required" v-slot="{errors}">
                                            <b-form-group label="Días de itinerario:" class="mb-2">
                                                <v-select :disabled="datosIncidente.idProyecto == ''"  :reduce="listaDiasItinerario =>listaDiasItinerario.id" label="dia" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIncidente.idDia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIncidente.idDia" :options="listaDiasItinerario">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Itinerarios'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="razonSocial" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Razón Social o Denominación Social:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón o denominación social" v-model="datosIncidente.razonSocial"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="ruc" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="RUC:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese RUC" v-model="datosIncidente.ruc"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="domicilio" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Domicilio (dirección, distrito, departamento, provincia):" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese domicilio" v-model="datosIncidente.domicilio"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="actividadEconomica" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tipo de Actividad Económica:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese actividad económica" v-model="datosIncidente.actividadEconomica"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="nTrabajadores" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="N° de trabajadores:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el n° de trabajadores" v-model="datosIncidente.nTrabajadores"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Trabajador">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="trabajador" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Apellidos y Nombres del Trabajador:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese apellidos y nombres del trabajador" v-model="datosIncidente.trabajador"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="dni" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="N° DNI/CE:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° DNI/CE" v-model="datosIncidente.dni"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="edad" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="Edad:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese edad" v-model="datosIncidente.edad"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="area" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Área:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese área" v-model="datosIncidente.area"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="puestoTrabajo" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Puesto de trabajo:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el puesto de trabajo" v-model="datosIncidente.puestoTrabajo"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="antiguedad" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Antiguedad del Empleo:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la antiguedad del empleo" v-model="datosIncidente.antiguedad"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="sexo" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Sexo:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidente.sexo" :options="[{value:1,text:'M'},{value:2,text:'F'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="turno" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Turno:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidente.turno" :options="[{value:1,text:'D'},{value:2,text:'T'},{value:3,text:'N'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="contrato" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tipo de Contrato:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de contrato" v-model="datosIncidente.tipoContrato"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="experiencia" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tiempo de experiencia en el puesto de trabajo:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tiempo de experiencia" v-model="datosIncidente.experiencia"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="horas" :rules="{required: true, numeric:true}" v-slot="validationContext">
                                            <b-form-group label="N° horas trabajadas en la jornada laboral:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° de horas trabajadas" v-model="datosIncidente.nHoras"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Investigación">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="tipoIncidente" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tipo de Incidente:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidente.tipoIncidente" :options="[{value:1,text:'Incidente peligroso'},{value:2,text:'Incidente'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="trabajadoresAfectados" :rules="{required: true, numeric:true}" v-slot="validationContext">
                                            <b-form-group label="N° de trabajadores afectados:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° de trabajadores afectados" v-model="datosIncidente.trabajadoresAfectados"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="pobladoresAfectados" :rules="{required: true, numeric:true}" v-slot="validationContext">
                                            <b-form-group label="N° de pobladores afectados:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° de pobladores afectados" v-model="datosIncidente.pobladoresAfectados"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="primerosAuxilios" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Detallar el tipo de atención en primeros auxilios:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese detalle del tipo de atención" v-model="datosIncidente.primerosAuxilios"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="fechaIncidente" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Fecha en que ocurrió el incidente:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosIncidente.fechaIncidente"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="horaIncidente" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Hora en que ocurrió el incidente:" class="mb-2">
                                                <b-form-input type="time" :state="getValidationState(validationContext)" v-model="datosIncidente.horaIncidente"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Fecha de inicio de la Investigación:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosIncidente.fechaInvestigacion"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="lugar" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Lugar exacto donde ocurrió el hecho:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el lugar donde ocurrió el hecho" v-model="datosIncidente.lugarIncidente"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>

                            <b-tab title="Descripción del incidente">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="hechos" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Describa los hechos:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese los hechos" v-model="datosIncidente.hechos"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!--   ******************************************************      -->
                                    <b-col md="6">
                                            <b-form-group label="Declaración Afectado:" class="mb-2">
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="datosIncidente.urlDeclaracionAfectado != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosIncidente.urlDeclaracionAfectado)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="datosIncidente.declaracionAfectado" :placeholder="datosIncidente.nombreDeclaracionAfectado == '' ?  'Elija un archivo o arrástrelo aquí...' : datosIncidente.nombreDeclaracionAfectado" accept=".pdf,.doc,.doxc,.xlsx/*" browse-text="Subir" v-on:change="handleFileUploadDocumentos"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                    </b-col>
                                    <!--   ******************************************************      -->

                                    <!--   ******************************************************      -->
                                    <b-col md="6">
                                            <b-form-group label="Declaración Testigos:" class="mb-2">
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="datosIncidente.urlDeclaracionTestigos != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosIncidente.urlDeclaracionTestigos)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="datosIncidente.declaracionTestigos" :placeholder="datosIncidente.nombreDeclaracionTestigos == '' ?  'Elija un archivo o arrástrelo aquí...' : datosIncidente.nombreDeclaracionTestigos" accept=".pdf,.doc,.doxc,.xlsx/*" browse-text="Subir" v-on:change="handleFileUploadDocumentos"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                    </b-col>
                                    <!--   ******************************************************      -->

                                    <!--   ******************************************************      -->
                                    <b-col md="6">
                                            <b-form-group label="Procedimientos:" class="mb-2">
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="datosIncidente.urlProcedimientos != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosIncidente.urlProcedimientos)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="datosIncidente.procedimientos" :placeholder="datosIncidente.nombreProcedimientos == '' ?  'Elija un archivo o arrástrelo aquí...' : datosIncidente.nombreProcedimientos" accept=".pdf,.doc,.doxc,.xlsx/*" browse-text="Subir" v-on:change="handleFileUploadDocumentos"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                    </b-col>
                                    <!--   ******************************************************      -->
                                </b-row>
                            </b-tab>

                            <b-tab title="Causas del incidente">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="subestandar" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Acto SubEstándar:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidente.actoSubestandar" :options="[{value:1,text:'No usar equipo de protección individual, usarlo en forma incorrecta'},
                                                                                                                                                              {value:2,text:'No cumplir procedimiento o método de trabajo establecido'},
                                                                                                                                                              {value:3,text:'Manipular equipo en movimiento/energizado/presurizado'},
                                                                                                                                                              {value:4,text:'Uso inapropiado de equipos, herramientas o instalaciones'},
                                                                                                                                                              {value:5,text:'Hacer caso omiso a los avisos de prevención'},
                                                                                                                                                              {value:6,text:'Poner inoperativos los dispositivos de seguridad'},
                                                                                                                                                              {value:7,text:'Operar el equipo a velocidad insegura'},
                                                                                                                                                              {value:8,text:'Usar equipos o herramientas en mal estado'},
                                                                                                                                                              {value:9,text:'Tomar posiciones o posturas incorrectas'},
                                                                                                                                                              {value:10,text:'Realizar el trabajo sin la capacitación necesario'},
                                                                                                                                                              {value:11,text:'Falta de atención, trabajar distraído'},
                                                                                                                                                              {value:12,text:'Jugar en el trabajo, hacer bromas'},
                                                                                                                                                              {value:13,text:'Actuar bajo los efectos de alcohol o drogas'},
                                                                                                                                                              {value:14,text:'Otros:(especificar)'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="condicionSubestandar" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Condición SubEstándar:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidente.condicionSubestandar" :options="[{value:1,text:'Falta de orden y limpieza'},
                                                                                                                                                                       {value:2,text:'Protección personal inadecuada'},
                                                                                                                                                                       {value:3,text:'Espacio reducido / movimiento restringido'},
                                                                                                                                                                       {value:4,text:'Accesos o salidas inadecuadas'},
                                                                                                                                                                       {value:5,text:'Escaleras portátiles o rampas sub estándares'},
                                                                                                                                                                       {value:6,text:'Andamios y plataformas sub estándares'},
                                                                                                                                                                       {value:7,text:'Herramientas y equipos en mal estado / sin guardas de seguridad'},
                                                                                                                                                                       {value:8,text:'Perímetro de Losas / aberturas en pisos / excavaciones, sin protección'},
                                                                                                                                                                       {value:9,text:'Instalaciones eléctricas en mal estado, sin protección necesaria'},
                                                                                                                                                                       {value:10,text:'Falta de señalización / señalización inadecuada'},
                                                                                                                                                                       {value:11,text:'Iluminación/visibilidad deficiente'},
                                                                                                                                                                       {value:12,text:'Agentes Químicos (polvo, gases, productos químicos'},
                                                                                                                                                                       {value:13,text:'Agentes físicos (ruído, radiación, iluminación, vibraciones, cargas, etc)'},
                                                                                                                                                                       {value:14,text:'Agentes Ergonómicos (Mov. Repetitivo, levantamiento de cargas'},
                                                                                                                                                                       {value:15,text:'Otros:(especificar)'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="factoresPersonales" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Factores Personales:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidente.factoresPersonales" :options="[{value:1,text:'Capacidad física inadecuada'},
                                                                                                                                                                       {value:2,text:'Capacidad mental inadecuada'},
                                                                                                                                                                       {value:3,text:'Tensión mental o psicológica'},
                                                                                                                                                                       {value:4,text:'Falta de conocimientos'},
                                                                                                                                                                       {value:5,text:'Falta de habilidad'},
                                                                                                                                                                       {value:6,text:'Motivación inapropiada'},
                                                                                                                                                                       {value:7,text:'Fatiga (estrés físico o emocional)'},
                                                                                                                                                                       {value:8,text:'Tiempo de reacción muy rápida o lenta'},
                                                                                                                                                                       {value:9,text:'Percepción inexacta del peligro'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="factoresTrabajo" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Factores de Trabajo:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidente.factoresTrabajo" :options="[{value:1,text:'Planeamiento/ingeniería inadecuada'},
                                                                                                                                                                    {value:2,text:'Liderazgo o supervisión inadecuada'},
                                                                                                                                                                   {value:3,text:'Normas y procedimientos de trabajo inadecuados o inexistentes'},
                                                                                                                                                                   {value:4,text:'Compra de equipos inadecuados/de mala calidad'},
                                                                                                                                                                   {value:5,text:'Mantenimiento o almacenamiento inadecuado'},
                                                                                                                                                                   {value:6,text:'Ausencia de prendas o equipos'},
                                                                                                                                                                   {value:7,text:'Herramientas y equipos inadecuados/inexistentes'},
                                                                                                                                                                   {value:8,text:'Uso y desgaste excesivo'},
                                                                                                                                                                   {value:9,text:'Comunicación inadecuada'},
                                                                                                                                                                   {value:10,text:'Ambiente/infraestructura inadecuados/inexistente'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>

                            <b-tab title="Medidas correctivas">
                                <b-row>

                                    <b-col md="12" class="mt-2">
                                        <span class="h6 text-muted">Lista de Medidas Correctivas</span>
                                        <CButton size="sm" class="float-right" color="dark" @click="agregarMedidas()">
                                            <i class="fas fa-plus fa-sm"></i> Agregar Medidas
                                        </CButton>
                                    </b-col>

                                    <b-col md="12" class="mt-2">
                                        <b-table :items="datosIncidente.listaMedidas" :fields="datosMedidas" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">

                                            <template v-slot:cell(descripcionMedida)="rowActividad">
                                                <b-form-input placeholder="Ingrese la descripcion" v-model.lazy="rowActividad.item.descripcionMedida" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(responsable)="rowActividad">
                                                <b-form-input placeholder="Ingrese el responsable" v-model.lazy="rowActividad.item.responsable" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(fechaEjecucion)="rowActividad">
                                                <b-form-input type="date" placeholder="Ingrese la fecha" v-model.lazy="rowActividad.item.fechaEjecucion" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(estadoImplementacion)="rowActividad">
                                                <b-form-select placeholder="Ingrese el estado de implementación" v-model.lazy="rowActividad.item.estadoImplementacion" rows="3" max-rows="6" :options="[{value:1,text:'Realizada'},{value:2,text:'Pendiente'},{value:3,text:'En ejecución'}]"></b-form-select>
                                            </template>

                                            <template #cell(acciones)="param">
                                                <b-button @click="quitarMedidas(param)" v-if="datosIncidente.listaMedidas.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>
                                </b-row>
                            </b-tab>

                            <b-tab title="Responsables del Registro">
                                <b-row>
                                    <b-col md="12" class="mt-2">
                                        <span class="h6 text-muted">Lista de Responsables del Registro</span>
                                        <CButton size="sm" class="float-right" color="dark" @click="agregarResponsable()">
                                            <i class="fas fa-plus fa-sm"></i> Agregar Responsable
                                        </CButton>
                                    </b-col>

                                    <b-col md="12" class="mt-2">
                                        <b-table :items="datosIncidente.listaResponsable" :fields="datosResponsable" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">

                                            <template v-slot:cell(nombreResponsable)="rowActividad">
                                                <b-form-input placeholder="Ingrese el nombre" v-model.lazy="rowActividad.item.nombreResponsable" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(cargo)="rowActividad">
                                                <b-form-input placeholder="Ingrese el cargo" v-model.lazy="rowActividad.item.cargo" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(fecha)="rowActividad">
                                                <b-form-input type="date" placeholder="Ingrese la fecha" v-model.lazy="rowActividad.item.fecha" rows="3" max-rows="6"></b-form-input>
                                            </template>

                                            <template v-slot:cell(firma)="row">
                                                <b-form-group>
                                                    <b-input-group>
                                                        <b-input-group-prepend v-if="row.item.urlFirma != ''">
                                                            <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlFirma)">
                                                                <i class="fas fa-download fa-xs"></i>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-file class="text-left" ref="file" v-model="row.item.firma" :placeholder="row.item.nombreFirma == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreFirma" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                    </b-input-group>
                                                </b-form-group>
                                            </template>

                                            <template #cell(acciones)="param">
                                                <b-button @click="quitarResponsable(param)" v-if="datosIncidente.listaResponsable.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>
                                </b-row>
                            </b-tab>

                        </b-tabs>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarIncidente = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarIncidente" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar Incidentes</span></h6>
            <CButtonClose @click="modalActualizarIncidente = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarIncidente)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Empleador Principal" active>
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                            <b-form-group label="Proyecto:" class="mb-2">
                                                <v-select @input="obtenerDiasProyecto" :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIncidenteActualizar.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIncidenteActualizar.idProyecto" :options="comboProyecto" @search:blur="blurProyectoActualizar">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="días de itinerario" rules="required" v-slot="{errors}">
                                            <b-form-group label="Días de itinerario:" class="mb-2">
                                                <v-select :disabled="datosIncidenteActualizar.idProyecto == ''"  :reduce="listaDiasItinerario =>listaDiasItinerario.id" label="dia" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosIncidenteActualizar.idDia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosIncidenteActualizar.idDia" :options="listaDiasItinerario">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Itinerarios'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="razonSocial" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Razón Social o Denominación Social:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón o denominación social" v-model="datosIncidenteActualizar.razonSocial"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="ruc" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="RUC:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese RUC" v-model="datosIncidenteActualizar.ruc"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="domicilio" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Domicilio (dirección, distrito, departamento, provincia):" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese domicilio" v-model="datosIncidenteActualizar.domicilio"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="actividadEconomica" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tipo de Actividad Económica:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese actividad económica" v-model="datosIncidenteActualizar.actividadEconomica"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="nTrabajadores" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="N° de trabajadores:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el n° de trabajadores" v-model="datosIncidenteActualizar.nTrabajadores"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Trabajador">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="trabajador" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Apellidos y Nombres del Trabajador:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese apellidos y nombres del trabajador" v-model="datosIncidenteActualizar.trabajador"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="dni" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="N° DNI/CE:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° DNI/CE" v-model="datosIncidenteActualizar.dni"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="edad" :rules="{required: true, numeric: true}" v-slot="validationContext">
                                            <b-form-group label="Edad:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese edad" v-model="datosIncidenteActualizar.edad"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="area" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Área:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese área" v-model="datosIncidenteActualizar.area"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="puestoTrabajo" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Puesto de trabajo:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el puesto de trabajo" v-model="datosIncidenteActualizar.puestoTrabajo"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="antiguedad" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Antiguedad del Empleo:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la antiguedad del empleo" v-model="datosIncidenteActualizar.antiguedad"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="sexo" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Sexo:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.sexo" :options="[{value:1,text:'M'},{value:2,text:'F'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="turno" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Turno:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.turno" :options="[{value:1,text:'D'},{value:2,text:'T'},{value:3,text:'N'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="contrato" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tipo de Contrato:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de contrato" v-model="datosIncidenteActualizar.tipoContrato"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="experiencia" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tiempo de experiencia en el puesto de trabajo:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tiempo de experiencia" v-model="datosIncidenteActualizar.experiencia"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="horas" :rules="{required: true, numeric:true}" v-slot="validationContext">
                                            <b-form-group label="N° horas trabajadas en la jornada laboral:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° de horas trabajadas" v-model="datosIncidenteActualizar.nHoras"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Investigación">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="tipoIncidente" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Tipo de Incidente:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.tipoIncidente" :options="[{value:1,text:'Incidente peligroso'},{value:2,text:'Incidente'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="trabajadoresAfectados" :rules="{required: true, numeric:true}" v-slot="validationContext">
                                            <b-form-group label="N° de trabajadores afectados:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° de trabajadores afectados" v-model="datosIncidenteActualizar.trabajadoresAfectados"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="pobladoresAfectados" :rules="{required: true, numeric:true}" v-slot="validationContext">
                                            <b-form-group label="N° de pobladores afectados:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese N° de pobladores afectados" v-model="datosIncidenteActualizar.pobladoresAfectados"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="primerosAuxilios" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Detallar el tipo de atención en primeros auxilios:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese detalle del tipo de atención" v-model="datosIncidenteActualizar.primerosAuxilios"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="fechaIncidente" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Fecha en que ocurrió el incidente:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.fechaIncidente"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="horaIncidente" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Hora en que ocurrió el incidente:" class="mb-2">
                                                <b-form-input type="time" :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.horaIncidente"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Fecha de inicio de la Investigación:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.fechaInvestigacion"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="lugar" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Lugar exacto donde ocurrió el hecho:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el lugar donde ocurrió el hecho" v-model="datosIncidenteActualizar.lugarIncidente"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Descripción del incidente">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="hechos" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Describa los hechos:" class="mb-2">
                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese los hechos" v-model="datosIncidenteActualizar.hechos"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!--   ******************************************************      -->
                                    <b-col md="6">
                                            <b-form-group label="Declaración Afectado:" class="mb-2">
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="datosIncidenteActualizar.urlDeclaracionAfectado != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosIncidenteActualizar.urlDeclaracionAfectado)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="datosIncidenteActualizar.declaracionAfectado" :placeholder="datosIncidenteActualizar.nombreDeclaracionAfectado == '' ?  'Elija un archivo o arrástrelo aquí...' : datosIncidenteActualizar.nombreDeclaracionAfectado" accept=".pdf,.doc,.doxc,.xlsx/*" browse-text="Subir" v-on:change="handleFileUploadDocumentos"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                    </b-col>
                                    <!--   ******************************************************      -->

                                    <!--   ******************************************************      -->
                                    <b-col md="6">
                                            <b-form-group label="Declaración Testigos:" class="mb-2">
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="datosIncidenteActualizar.urlDeclaracionTestigos != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosIncidenteActualizar.urlDeclaracionTestigos)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="datosIncidenteActualizar.declaracionTestigos" :placeholder="datosIncidenteActualizar.nombreDeclaracionTestigos == '' ?  'Elija un archivo o arrástrelo aquí...' : datosIncidenteActualizar.nombreDeclaracionTestigos" accept=".pdf,.doc,.doxc,.xlsx/*" browse-text="Subir" v-on:change="handleFileUploadDocumentos"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                    </b-col>
                                    <!--   ******************************************************      -->

                                    <!--   ******************************************************      -->
                                    <b-col md="6">
                                            <b-form-group label="Procedimientos:" class="mb-2">
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="datosIncidenteActualizar.urlProcedimientos != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosIncidenteActualizar.urlProcedimientos)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="datosIncidenteActualizar.procedimientos" :placeholder="datosIncidenteActualizar.nombreProcedimientos == '' ?  'Elija un archivo o arrástrelo aquí...' : datosIncidenteActualizar.nombreProcedimientos" accept=".pdf,.doc,.doxc,.xlsx/*" browse-text="Subir" v-on:change="handleFileUploadDocumentos"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                    </b-col>
                                    <!--   ******************************************************      -->

                                </b-row>
                            </b-tab>

                            <b-tab title="Causas del incidente">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="subestandar" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Acto SubEstándar:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.actoSubestandar" :options="[{value:1,text:'No usar equipo de protección individual, usarlo en forma incorrecta'},
                                                                                                                                                              {value:2,text:'No cumplir procedimiento o método de trabajo establecido'},
                                                                                                                                                              {value:3,text:'Manipular equipo en movimiento/energizado/presurizado'},
                                                                                                                                                              {value:4,text:'Uso inapropiado de equipos, herramientas o instalaciones'},
                                                                                                                                                              {value:5,text:'Hacer caso omiso a los avisos de prevención'},
                                                                                                                                                              {value:6,text:'Poner inoperativos los dispositivos de seguridad'},
                                                                                                                                                              {value:7,text:'Operar el equipo a velocidad insegura'},
                                                                                                                                                              {value:8,text:'Usar equipos o herramientas en mal estado'},
                                                                                                                                                              {value:9,text:'Tomar posiciones o posturas incorrectas'},
                                                                                                                                                              {value:10,text:'Realizar el trabajo sin la capacitación necesario'},
                                                                                                                                                              {value:11,text:'Falta de atención, trabajar distraído'},
                                                                                                                                                              {value:12,text:'Jugar en el trabajo, hacer bromas'},
                                                                                                                                                              {value:13,text:'Actuar bajo los efectos de alcohol o drogas'},
                                                                                                                                                              {value:14,text:'Otros:(especificar)'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="condicionSubestandar" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Condición SubEstándar:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.condicionSubestandar" :options="[{value:1,text:'Falta de orden y limpieza'},
                                                                                                                                                                       {value:2,text:'Protección personal inadecuada'},
                                                                                                                                                                       {value:3,text:'Espacio reducido / movimiento restringido'},
                                                                                                                                                                       {value:4,text:'Accesos o salidas inadecuadas'},
                                                                                                                                                                       {value:5,text:'Escaleras portátiles o rampas sub estándares'},
                                                                                                                                                                       {value:6,text:'Andamios y plataformas sub estándares'},
                                                                                                                                                                       {value:7,text:'Herramientas y equipos en mal estado / sin guardas de seguridad'},
                                                                                                                                                                       {value:8,text:'Perímetro de Losas / aberturas en pisos / excavaciones, sin protección'},
                                                                                                                                                                       {value:9,text:'Instalaciones eléctricas en mal estado, sin protección necesaria'},
                                                                                                                                                                       {value:10,text:'Falta de señalización / señalización inadecuada'},
                                                                                                                                                                       {value:11,text:'Iluminación/visibilidad deficiente'},
                                                                                                                                                                       {value:12,text:'Agentes Químicos (polvo, gases, productos químicos'},
                                                                                                                                                                       {value:13,text:'Agentes físicos (ruído, radiación, iluminación, vibraciones, cargas, etc)'},
                                                                                                                                                                       {value:14,text:'Agentes Ergonómicos (Mov. Repetitivo, levantamiento de cargas'},
                                                                                                                                                                       {value:15,text:'Otros:(especificar)'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="factoresPersonales" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Factores Personales:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.factoresPersonales" :options="[{value:1,text:'Capacidad física inadecuada'},
                                                                                                                                                                       {value:2,text:'Capacidad mental inadecuada'},
                                                                                                                                                                       {value:3,text:'Tensión mental o psicológica'},
                                                                                                                                                                       {value:4,text:'Falta de conocimientos'},
                                                                                                                                                                       {value:5,text:'Falta de habilidad'},
                                                                                                                                                                       {value:6,text:'Motivación inapropiada'},
                                                                                                                                                                       {value:7,text:'Fatiga (estrés físico o emocional)'},
                                                                                                                                                                       {value:8,text:'Tiempo de reacción muy rápida o lenta'},
                                                                                                                                                                       {value:9,text:'Percepción inexacta del peligro'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="6">
                                        <validation-provider name="factoresTrabajo" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Factores de Trabajo:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosIncidenteActualizar.factoresTrabajo" :options="[{value:1,text:'Planeamiento/ingeniería inadecuada'},
                                                                                                                                                                    {value:2,text:'Liderazgo o supervisión inadecuada'},
                                                                                                                                                                   {value:3,text:'Normas y procedimientos de trabajo inadecuados o inexistentes'},
                                                                                                                                                                   {value:4,text:'Compra de equipos inadecuados/de mala calidad'},
                                                                                                                                                                   {value:5,text:'Mantenimiento o almacenamiento inadecuado'},
                                                                                                                                                                   {value:6,text:'Ausencia de prendas o equipos'},
                                                                                                                                                                   {value:7,text:'Herramientas y equipos inadecuados/inexistentes'},
                                                                                                                                                                   {value:8,text:'Uso y desgaste excesivo'},
                                                                                                                                                                   {value:9,text:'Comunicación inadecuada'},
                                                                                                                                                                   {value:10,text:'Ambiente/infraestructura inadecuados/inexistente'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>

                            <b-tab title="Medidas correctivas">
                                <b-row>

                                    <b-col md="12" class="mt-2">
                                        <span class="h6 text-muted">Lista de Medidas Correctivas</span>
                                        <CButton size="sm" class="float-right" color="dark" @click="agregarMedidasActualizar()">
                                            <i class="fas fa-plus fa-sm"></i> Agregar Medidas
                                        </CButton>
                                    </b-col>

                                    <b-col md="12" class="mt-2">
                                        <b-table :items="datosIncidenteActualizar.listaMedidas" :fields="datosMedidas" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">

                                            <template v-slot:cell(descripcionMedida)="rowActividad">
                                                <b-form-input placeholder="Ingrese la descripcion" v-model.lazy="rowActividad.item.descripcionMedida" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(responsable)="rowActividad">
                                                <b-form-input placeholder="Ingrese el responsable" v-model.lazy="rowActividad.item.responsable" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(fechaEjecucion)="rowActividad">
                                                <b-form-input type="date" placeholder="Ingrese la fecha" v-model.lazy="rowActividad.item.fechaEjecucion" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(estadoImplementacion)="rowActividad">
                                                <b-form-select placeholder="Ingrese el estado de implementación" v-model.lazy="rowActividad.item.estadoImplementacion" rows="3" max-rows="6" :options="[{value:1,text:'Realizada'},{value:2,text:'Pendiente'},{value:3,text:'En ejecución'}]"></b-form-select>
                                            </template>

                                            <template #cell(acciones)="param">
                                                <b-button @click="quitarMedidasActualizar(param)" v-if="datosIncidenteActualizar.listaMedidas.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>
                                </b-row>
                            </b-tab>

                            <b-tab title="Responsables del Registro">
                                <b-row>
                                    <b-col md="12" class="mt-2">
                                        <span class="h6 text-muted">Lista de Responsables del Registro</span>
                                        <CButton size="sm" class="float-right" color="dark" @click="agregarResponsableActualizar()">
                                            <i class="fas fa-plus fa-sm"></i> Agregar Responsable
                                        </CButton>
                                    </b-col>

                                    <b-col md="12" class="mt-2">
                                        <b-table :items="datosIncidenteActualizar.listaResponsable" :fields="datosResponsable" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">

                                            <template v-slot:cell(nombreResponsable)="rowActividad">
                                                <b-form-input placeholder="Ingrese el nombre" v-model.lazy="rowActividad.item.nombreResponsable" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(cargo)="rowActividad">
                                                <b-form-input placeholder="Ingrese el cargo" v-model.lazy="rowActividad.item.cargo" rows="3" max-rows="6"></b-form-input>
                                            </template>
                                            <template v-slot:cell(fecha)="rowActividad">
                                                <b-form-input type="date" placeholder="Ingrese la fecha" v-model.lazy="rowActividad.item.fecha" rows="3" max-rows="6"></b-form-input>
                                            </template>

                                            <template v-slot:cell(firma)="row">
                                                <b-form-group>
                                                    <b-input-group>
                                                        <b-input-group-prepend v-if="row.item.urlFirma != ''">
                                                            <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlFirma)">
                                                                <i class="fas fa-download fa-xs"></i>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-file class="text-left" ref="file" v-model="row.item.firma" :placeholder="row.item.nombreFirma == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreFirma" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                    </b-input-group>
                                                </b-form-group>
                                            </template>

                                            <template #cell(acciones)="param">
                                                <b-button @click="quitarResponsableActualizar(param)" v-if="datosIncidenteActualizar.listaResponsable.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarIncidente = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import firebase from 'firebase';
const dbIncidente = firebase.firestore().collection('incidente');
const dbProyecto = firebase.firestore().collection('proyectos');
const dbItinerario = firebase.firestore().collection('itinerarios');

export default {

    data() {
        return {

            disabled: false,
            listaIncidente: [],
            listaDiasItinerario: [],
            arrayDiasItinerario: [],
            camposIncidente: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "idProyecto",
                    label: "Proyecto",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Razón Social",
                    class: "text-center",
                },
                {
                    key: "tipoIncidente",
                    label: "Tipo de Incidente",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            datosMedidas: [{
                    key: 'descripcionMedida',
                    label: 'Descripción Medida',
                    class: 'text-center'
                },
                {
                    key: 'responsable',
                    label: 'Responsable',
                    class: 'text-center'
                },
                {
                    key: 'fechaEjecucion',
                    label: 'Fecha Ejecución',
                    class: 'text-center'
                },
                {
                    key: 'estadoImplementacion',
                    label: 'Estado Implementación',
                    class: 'text-center'
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center",
                }

            ],

            datosResponsable: [{
                    key: 'nombreResponsable',
                    label: 'Nombre Responsable',
                    class: 'text-center'
                },
                {
                    key: 'cargo',
                    label: 'Cargo',
                    class: 'text-center'
                },
                {
                    key: 'fecha',
                    label: 'Fecha',
                    class: 'text-center'
                },
                {
                    key: 'firma',
                    label: 'Firma',
                    class: 'text-center'
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center",
                }

            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarIncidente: false,
            modalActualizarIncidente: false,

            datosIncidente: {
                idIncidente: '',
                idCliente: '',
                idDia: null,
                idProyecto: '',
                razonSocial: '',
                ruc: '',
                domicilio: '',
                actividadEconomica: '',
                nTrabajadores: '',
                trabajador: '',
                dni: '',
                edad: '',
                area: '',
                puestoTrabajo: '',
                antiguedad: '',
                sexo: '',
                turno: '',
                tipoContrato: '',
                experiencia: '',
                nHoras: '',
                tipoIncidente: '',
                trabajadoresAfectados: '',
                pobladoresAfectados: '',
                primerosAuxilios: '',
                fechaIncidente: '',
                horaIncidente: '',
                fechaInvestigacion: '',
                lugarIncidente: '',
                hechos: '',
                nombre: '',

                declaracionAfectado: null,
                urlDeclaracionAfectado: '',
                nombreDeclaracionAfectado: '',
                declaracionTestigos: null,
                urlDeclaracionTestigos: '',
                nombreDeclaracionTestigos: '',
                procedimientos: null,
                urlProcedimientos: '',
                nombreProcedimientos: '',

                actoSubestandar: '',
                condicionSubestandar: '',
                factoresPersonales: '',
                factoresTrabajo: '',

                listaMedidas: [{
                    descripcionMedida: '',
                    responsable: '',
                    fechaEjecucion: '',
                    estadoImplementacion: '',
                }],

                listaResponsable: [{
                    nombreResponsable: '',
                    cargo: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
                }],
            },
            datosIncidenteActualizar: {
                idIncidente: '',
                idCliente: '',
                idProyecto: '',
                idDia: null,
                razonSocial: '',
                ruc: '',
                domicilio: '',
                actividadEconomica: '',
                nTrabajadores: '',
                trabajador: '',
                dni: '',
                edad: '',
                area: '',
                puestoTrabajo: '',
                antiguedad: '',
                sexo: '',
                turno: '',
                tipoContrato: '',
                experiencia: '',
                nHoras: '',
                tipoIncidente: '',
                trabajadoresAfectados: '',
                pobladoresAfectados: '',
                primerosAuxilios: '',
                fechaIncidente: '',
                horaIncidente: '',
                fechaInvestigacion: '',
                lugarIncidente: '',
                hechos: '',

                declaracionAfectado: null,
                urlDeclaracionAfectado: '',
                nombreDeclaracionAfectado: '',
                declaracionTestigos: null,
                urlDeclaracionTestigos: '',
                nombreDeclaracionTestigos: '',
                procedimientos: null,
                urlProcedimientos: '',
                nombreProcedimientos: '',

                actoSubestandar: '',
                condicionSubestandar: '',
                factoresPersonales: '',
                factoresTrabajo: '',

                listaMedidas: [{
                    descripcionMedida: '',
                    responsable: '',
                    fechaEjecucion: '',
                    estadoImplementacion: '',
                }],

                listaResponsable: [{
                    nombreResponsable: '',
                    cargo: '',
                    fecha: '',
                    firma: null,
                    urlFirma: '',
                    nombreFirma: '',
                }],
            },
            comboProyecto: [],
        }
    },
    methods: {

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedFormActualizar.refs.proyecto.validate();
        },
        //-----------------------------------------------------------------
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
            }
        },
        handleFileUploadDocumentos(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(pdf|doc|xlsx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
            }
        },
        descargarDocumento(url) {
            window.open(url)
        },
        //-----------------------------------------------------------------
        agregarMedidas() {
            let me = this;
            me.datosIncidente.listaMedidas.push({
                descripcionMedida: '',
                responsable: '',
                fechaEjecucion: '',
                estadoImplementacion: '',
            })
        },
        quitarMedidas(param) {
            let me = this;
            me.datosIncidente.listaMedidas.splice(param.index, 1);
        },
        agregarMedidasActualizar() {
            let me = this;
            me.datosIncidenteActualizar.listaMedidas.push({
                descripcionMedida: '',
                responsable: '',
                fechaEjecucion: '',
                estadoImplementacion: '',
            })
        },
        quitarMedidasActualizar(param) {
            let me = this;
            me.datosIncidenteActualizar.listaMedidas.splice(param.index, 1);
        },
        agregarResponsable() {
            let me = this;
            me.datosIncidente.listaResponsable.push({
                nombreResponsable: '',
                cargo: '',
                fecha: '',
                firma: null,
                urlFirma: '',
                nombreFirma: '',
            })
        },
        quitarResponsable(param) {
            let me = this;
            me.datosIncidente.listaResponsable.splice(param.index, 1);
        },
        agregarResponsableActualizar() {
            let me = this;
            me.datosIncidenteActualizar.listaResponsable.push({
                nombreResponsable: '',
                cargo: '',
                fecha: '',
                firma: null,
                urlFirma: '',
                nombreFirma: '',
            })
        },
        quitarResponsableActualizar(param) {
            let me = this;
            me.datosIncidenteActualizar.listaResponsable.splice(param.index, 1);
        },

        listarProyectos() {
            let me = this;
            dbProyecto
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosIncidente.idCliente))
                .get()
                .then((querySnapshot) => {
                    me.comboProyecto = [];
                    querySnapshot.forEach((doc) => {
                        me.comboProyecto.push({
                            idProyecto: doc.id,
                            nombre: doc.data().nombre,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.comboProyecto.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
                    me.listarIncidente();
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarItinerario() {
            let me = this;
            dbItinerario
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosIncidente.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.arrayDiasItinerario = [];
                    let itinerarios = [];
                    querySnapshot.forEach((doc) => {
                        itinerarios.push({
                            idItinerario: doc.id,
                            idProyecto: doc.data().idProyecto,
                            listaDias: doc.data().listaDias,
                        });
                    });
                    itinerarios.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                    for (const i in itinerarios) {
                        for (const j in itinerarios[i].listaDias) {
                            me.arrayDiasItinerario.push({
                                idItinerario: itinerarios[i].idItinerario,
                                idProyecto: itinerarios[i].idProyecto,
                                dia: itinerarios[i].listaDias[j].nombre,
                                id: j
                            });
                        }
                    }
                });
        },
        listarIncidente() {
            let me = this;
            dbIncidente
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosIncidente.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaIncidente = [];
                    querySnapshot.forEach((doc) => {
                        me.listaIncidente.push({
                            idIncidente: doc.id,
                            idCliente: doc.data().idCliente,
                            idProyecto: doc.data().idProyecto,
                            idDia: doc.data().idDia,
                            razonSocial: doc.data().razonSocial,
                            ruc: doc.data().ruc,
                            domicilio: doc.data().domicilio,
                            actividadEconomica: doc.data().actividadEconomica,
                            nTrabajadores: doc.data().nTrabajadores,
                            trabajador: doc.data().trabajador,
                            dni: doc.data().dni,
                            edad: doc.data().edad,
                            area: doc.data().area,
                            puestoTrabajo: doc.data().puestoTrabajo,
                            antiguedad: doc.data().antiguedad,
                            sexo: doc.data().sexo,
                            turno: doc.data().turno,
                            tipoContrato: doc.data().tipoContrato,
                            experiencia: doc.data().experiencia,
                            nHoras: doc.data().nHoras,
                            tipoIncidente: doc.data().tipoIncidente,
                            trabajadoresAfectados: doc.data().trabajadoresAfectados,
                            pobladoresAfectados: doc.data().pobladoresAfectados,
                            primerosAuxilios: doc.data().primerosAuxilios,
                            fechaIncidente: doc.data().fechaIncidente,
                            horaIncidente: doc.data().horaIncidente,
                            fechaInvestigacion: doc.data().fechaInvestigacion,
                            lugarIncidente: doc.data().lugarIncidente,
                            hechos: doc.data().hechos,
                            declaracionAfectado: doc.data().declaracionAfectado,
                            urlDeclaracionAfectado: doc.data().urlDeclaracionAfectado,
                            nombreDeclaracionAfectado: doc.data().nombreDeclaracionAfectado,
                            declaracionTestigos: doc.data().declaracionTestigos,
                            urlDeclaracionTestigos: doc.data().urlDeclaracionTestigos,
                            nombreDeclaracionTestigos: doc.data().nombreDeclaracionTestigos,
                            procedimientos: doc.data().procedimientos,
                            urlProcedimientos: doc.data().urlProcedimientos,
                            nombreProcedimientos: doc.data().nombreProcedimientos,
                            actoSubestandar: doc.data().actoSubestandar,
                            condicionSubestandar: doc.data().condicionSubestandar,
                            factoresPersonales: doc.data().factoresPersonales,
                            factoresTrabajo: doc.data().factoresTrabajo,
                            listaMedidas: doc.data().listaMedidas,
                            listaResponsable: doc.data().listaResponsable,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaIncidente.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                    me.filasTotales = me.listaIncidente.length;
                });
        },

        async registrarIncidente() {
            let me = this;
            me.disabled = true;
            //-------------------------------------------------------------
            for (const i in me.datosIncidente.listaResponsable) {
                if (me.datosIncidente.listaResponsable[i].firma != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidente.listaResponsable[i].firma;
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Firmas/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidente.listaResponsable[i].firma = null;
                            me.datosIncidente.listaResponsable[i].nombreFirma = file.name;
                            me.datosIncidente.listaResponsable[i].urlFirma = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidente.listaResponsable[i].nombreFirma = '';
                        me.datosIncidente.listaResponsable[i].urlFirma = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosIncidente) {
                if (me.datosIncidente.declaracionAfectado != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidente.declaracionAfectado;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Declaración_Afectado/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidente.declaracionAfectado = null;
                            me.datosIncidente.nombreDeclaracionAfectado = file.name;
                            me.datosIncidente.urlDeclaracionAfectado = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidente.nombreDeclaracionAfectado = '';
                        me.datosIncidente.urlDeclaracionAfectado = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosIncidente) {
                if (me.datosIncidente.declaracionTestigos != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidente.declaracionTestigos;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Declaración_Testigos/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidente.declaracionTestigos = null;
                            me.datosIncidente.nombreDeclaracionTestigos = file.name;
                            me.datosIncidente.urlDeclaracionTestigos = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidente.nombreDeclaracionTestigos = '';
                        me.datosIncidente.urlDeclaracionTestigos = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosIncidente) {
                if (me.datosIncidente.procedimientos != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidente.procedimientos;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Procedimientos/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidente.procedimientos = null;
                            me.datosIncidente.nombreProcedimientos = file.name;
                            me.datosIncidente.urlProcedimientos = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidente.nombreProcedimientos = '';
                        me.datosIncidente.urlProcedimientos = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            //-------------------------------------------------------------
            dbIncidente.add({
                    idCliente: me.datosIncidente.idCliente,
                    idProyecto: me.datosIncidente.idProyecto,
                    idDia: me.datosIncidente.idDia,
                    razonSocial: me.datosIncidente.razonSocial,
                    ruc: me.datosIncidente.ruc,
                    domicilio: me.datosIncidente.domicilio,
                    actividadEconomica: me.datosIncidente.actividadEconomica,
                    nTrabajadores: me.datosIncidente.nTrabajadores,
                    trabajador: me.datosIncidente.trabajador,
                    dni: me.datosIncidente.dni,
                    edad: me.datosIncidente.edad,
                    area: me.datosIncidente.area,
                    puestoTrabajo: me.datosIncidente.puestoTrabajo,
                    antiguedad: me.datosIncidente.antiguedad,
                    sexo: me.datosIncidente.sexo,
                    turno: me.datosIncidente.turno,
                    tipoContrato: me.datosIncidente.tipoContrato,
                    experiencia: me.datosIncidente.experiencia,
                    nHoras: me.datosIncidente.nHoras,
                    tipoIncidente: me.datosIncidente.tipoIncidente,
                    trabajadoresAfectados: me.datosIncidente.trabajadoresAfectados,
                    pobladoresAfectados: me.datosIncidente.pobladoresAfectados,
                    primerosAuxilios: me.datosIncidente.primerosAuxilios,
                    fechaIncidente: me.datosIncidente.fechaIncidente,
                    horaIncidente: me.datosIncidente.horaIncidente,
                    fechaInvestigacion: me.datosIncidente.fechaInvestigacion,
                    lugarIncidente: me.datosIncidente.lugarIncidente,
                    hechos: me.datosIncidente.hechos,
                    declaracionAfectado: me.datosIncidente.declaracionAfectado,
                    urlDeclaracionAfectado: me.datosIncidente.urlDeclaracionAfectado,
                    nombreDeclaracionAfectado: me.datosIncidente.nombreDeclaracionAfectado,
                    declaracionTestigos: me.datosIncidente.declaracionTestigos,
                    urlDeclaracionTestigos: me.datosIncidente.urlDeclaracionTestigos,
                    nombreDeclaracionTestigos: me.datosIncidente.nombreDeclaracionTestigos,
                    procedimientos: me.datosIncidente.procedimientos,
                    urlProcedimientos: me.datosIncidente.urlProcedimientos,
                    nombreProcedimientos: me.datosIncidente.nombreProcedimientos,
                    actoSubestandar: me.datosIncidente.actoSubestandar,
                    condicionSubestandar: me.datosIncidente.condicionSubestandar,
                    factoresPersonales: me.datosIncidente.factoresPersonales,
                    factoresTrabajo: me.datosIncidente.factoresTrabajo,

                    listaMedidas: me.datosIncidente.listaMedidas,
                    listaResponsable: me.datosIncidente.listaResponsable,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarIncidente = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        async actualizarIncidente() {
            let me = this;
            me.disabled = true;
            //------------------------------------------------------------------------
            for (const i in me.datosIncidenteActualizar.listaResponsable) {
                if (me.datosIncidenteActualizar.listaResponsable[i].firma != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidenteActualizar.listaResponsable[i].firma;
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Firmas/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidenteActualizar.listaResponsable[i].firma = null;
                            me.datosIncidenteActualizar.listaResponsable[i].nombreFirma = file.name;
                            me.datosIncidenteActualizar.listaResponsable[i].urlFirma = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidenteActualizar.listaResponsable[i].nombreFirma = '';
                        me.datosIncidenteActualizar.listaResponsable[i].urlFirma = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosIncidenteActualizar) {
                if (me.datosIncidenteActualizar.declaracionAfectado != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidenteActualizar.declaracionAfectado;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Declaración_Afectado/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidenteActualizar.declaracionAfectado = null;
                            me.datosIncidenteActualizar.nombreDeclaracionAfectado = file.name;
                            me.datosIncidenteActualizar.urlDeclaracionAfectado = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidenteActualizar.nombreDeclaracionAfectado = '';
                        me.datosIncidenteActualizar.urlDeclaracionAfectado = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosIncidenteActualizar) {
                if (me.datosIncidenteActualizar.declaracionTestigos != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidenteActualizar.declaracionTestigos;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Declaración_Testigos/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidenteActualizar.declaracionTestigos = null;
                            me.datosIncidenteActualizar.nombreDeclaracionTestigos = file.name;
                            me.datosIncidenteActualizar.urlDeclaracionTestigos = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidenteActualizar.nombreDeclaracionTestigos = '';
                        me.datosIncidenteActualizar.urlDeclaracionTestigos = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            for (const i in me.datosIncidenteActualizar) {
                if (me.datosIncidenteActualizar.procedimientos != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosIncidenteActualizar.procedimientos;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`Incidentes/Procedimientos/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosIncidenteActualizar.procedimientos = null;
                            me.datosIncidenteActualizar.nombreProcedimientos = file.name;
                            me.datosIncidenteActualizar.urlProcedimientos = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosIncidenteActualizar.nombreProcedimientos = '';
                        me.datosIncidenteActualizar.urlProcedimientos = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            //------------------------------------------------------------------------
            dbIncidente.doc(me.datosIncidenteActualizar.idIncidente).update({
                    idCliente: me.datosIncidenteActualizar.idCliente,
                    idProyecto: me.datosIncidenteActualizar.idProyecto,
                    idDia: me.datosIncidenteActualizar.idDia,
                    razonSocial: me.datosIncidenteActualizar.razonSocial,
                    ruc: me.datosIncidenteActualizar.ruc,
                    domicilio: me.datosIncidenteActualizar.domicilio,
                    actividadEconomica: me.datosIncidenteActualizar.actividadEconomica,
                    nTrabajadores: me.datosIncidenteActualizar.nTrabajadores,
                    trabajador: me.datosIncidenteActualizar.trabajador,
                    dni: me.datosIncidenteActualizar.dni,
                    edad: me.datosIncidenteActualizar.edad,
                    area: me.datosIncidenteActualizar.area,
                    puestoTrabajo: me.datosIncidenteActualizar.puestoTrabajo,
                    antiguedad: me.datosIncidenteActualizar.antiguedad,
                    sexo: me.datosIncidenteActualizar.sexo,
                    turno: me.datosIncidenteActualizar.turno,
                    tipoContrato: me.datosIncidenteActualizar.tipoContrato,
                    experiencia: me.datosIncidenteActualizar.experiencia,
                    nHoras: me.datosIncidenteActualizar.nHoras,
                    tipoIncidente: me.datosIncidenteActualizar.tipoIncidente,
                    trabajadoresAfectados: me.datosIncidenteActualizar.trabajadoresAfectados,
                    pobladoresAfectados: me.datosIncidenteActualizar.pobladoresAfectados,
                    primerosAuxilios: me.datosIncidenteActualizar.primerosAuxilios,
                    fechaIncidente: me.datosIncidenteActualizar.fechaIncidente,
                    horaIncidente: me.datosIncidenteActualizar.horaIncidente,
                    fechaInvestigacion: me.datosIncidenteActualizar.fechaInvestigacion,
                    lugarIncidente: me.datosIncidenteActualizar.lugarIncidente,
                    hechos: me.datosIncidenteActualizar.hechos,
                    declaracionAfectado: me.datosIncidenteActualizar.declaracionAfectado,
                    // urlDeclaracionAfectado: me.datosIncidenteActualizar.urlDeclaracionAfectado,
                    nombreDeclaracionAfectado: me.datosIncidenteActualizar.nombreDeclaracionAfectado,
                    declaracionTestigos: me.datosIncidenteActualizar.declaracionTestigos,
                    urlDeclaracionTestigos: me.datosIncidenteActualizar.urlDeclaracionTestigos,
                    nombreDeclaracionTestigos: me.datosIncidenteActualizar.nombreDeclaracionTestigos,
                    procedimientos: me.datosIncidenteActualizar.procedimientos,
                    urlProcedimientos: me.datosIncidenteActualizar.urlProcedimientos,
                    nombreProcedimientos: me.datosIncidenteActualizar.nombreProcedimientos,
                    actoSubestandar: me.datosIncidenteActualizar.actoSubestandar,
                    condicionSubestandar: me.datosIncidenteActualizar.condicionSubestandar,
                    factoresPersonales: me.datosIncidenteActualizar.factoresPersonales,
                    factoresTrabajo: me.datosIncidenteActualizar.factoresTrabajo,
                    listaMedidas: me.datosIncidenteActualizar.listaMedidas,
                    listaResponsable: me.datosIncidenteActualizar.listaResponsable,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarIncidente = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarIncidente(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el incidente?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbIncidente.doc(param.item.idIncidente).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirModalActualizarIncidente(param) {
            this.datosIncidenteActualizar.idIncidente = param.item.idIncidente;
            this.datosIncidenteActualizar.idProyecto = param.item.idProyecto;
            this.obtenerDiasProyecto(this.datosIncidenteActualizar.idProyecto);
            this.datosIncidenteActualizar.idDia = param.item.idDia ;
            this.datosIncidenteActualizar.razonSocial = param.item.razonSocial;
            this.datosIncidenteActualizar.ruc = param.item.ruc;
            this.datosIncidenteActualizar.domicilio = param.item.domicilio;
            this.datosIncidenteActualizar.actividadEconomica = param.item.actividadEconomica;
            this.datosIncidenteActualizar.nTrabajadores = param.item.nTrabajadores;
            this.datosIncidenteActualizar.trabajador = param.item.trabajador;
            this.datosIncidenteActualizar.dni = param.item.dni;
            this.datosIncidenteActualizar.edad = param.item.edad;
            this.datosIncidenteActualizar.area = param.item.area;
            this.datosIncidenteActualizar.puestoTrabajo = param.item.puestoTrabajo;
            this.datosIncidenteActualizar.antiguedad = param.item.antiguedad;
            this.datosIncidenteActualizar.sexo = param.item.sexo;
            this.datosIncidenteActualizar.turno = param.item.turno;
            this.datosIncidenteActualizar.tipoContrato = param.item.tipoContrato;
            this.datosIncidenteActualizar.experiencia = param.item.experiencia;
            this.datosIncidenteActualizar.nHoras = param.item.nHoras;
            this.datosIncidenteActualizar.tipoIncidente = param.item.tipoIncidente;
            this.datosIncidenteActualizar.trabajadoresAfectados = param.item.trabajadoresAfectados;
            this.datosIncidenteActualizar.pobladoresAfectados = param.item.pobladoresAfectados;
            this.datosIncidenteActualizar.primerosAuxilios = param.item.primerosAuxilios;
            this.datosIncidenteActualizar.fechaIncidente = param.item.fechaIncidente;
            this.datosIncidenteActualizar.horaIncidente = param.item.horaIncidente;
            this.datosIncidenteActualizar.fechaInvestigacion = param.item.fechaInvestigacion;
            this.datosIncidenteActualizar.lugarIncidente = param.item.lugarIncidente;
            this.datosIncidenteActualizar.hechos = param.item.hechos;
            this.datosIncidenteActualizar.declaracionAfectado = param.item.declaracionAfectado;
            this.datosIncidenteActualizar.urlDeclaracionAfectado = param.item.urlDeclaracionAfectado;
            this.datosIncidenteActualizar.nombreDeclaracionAfectado = param.item.nombreDeclaracionAfectado;
            this.datosIncidenteActualizar.declaracionTestigos = param.item.declaracionTestigos;
            this.datosIncidenteActualizar.urlDeclaracionTestigos = param.item.urlDeclaracionTestigos;
            this.datosIncidenteActualizar.nombreDeclaracionTestigos = param.item.nombreDeclaracionTestigos;
            this.datosIncidenteActualizar.procedimientos = param.item.procedimientos;
            this.datosIncidenteActualizar.urlProcedimientos = param.item.urlProcedimientos;
            this.datosIncidenteActualizar.nombreProcedimientos = param.item.nombreProcedimientos;
            this.datosIncidenteActualizar.actoSubestandar = param.item.actoSubestandar;
            this.datosIncidenteActualizar.condicionSubestandar = param.item.condicionSubestandar;
            this.datosIncidenteActualizar.factoresPersonales = param.item.factoresPersonales;
            this.datosIncidenteActualizar.factoresTrabajo = param.item.factoresTrabajo;

            this.datosIncidenteActualizar.listaMedidas = param.item.listaMedidas;
            this.datosIncidenteActualizar.listaResponsable = param.item.listaResponsable;

            this.datosIncidenteActualizar.datosIncidente = param.item.datosIncidente;
            this.modalActualizarIncidente = true
        },
        obtenerDiasProyecto(e) {
            this.datosIncidente.idDia = null;
            this.datosIncidenteActualizar.idDia = null;
            this.listaDiasItinerario = this.arrayDiasItinerario.filter(x => x.idProyecto == e);
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarIncidente() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosIncidente.idProyecto = '';
            this.datosIncidente.idDia = null;
            this.datosIncidente.razonSocial = '';
            this.datosIncidente.ruc = '';
            this.datosIncidente.domicilio = '';
            this.datosIncidente.actividadEconomica = '';
            this.datosIncidente.nTrabajadores = '';
            this.datosIncidente.trabajador = '';
            this.datosIncidente.dni = '';
            this.datosIncidente.edad = '';
            this.datosIncidente.area = '';
            this.datosIncidente.puestoTrabajo = '';
            this.datosIncidente.antiguedad = '';
            this.datosIncidente.sexo = '';
            this.datosIncidente.turno = '';
            this.datosIncidente.tipoContrato = '';
            this.datosIncidente.experiencia = '';
            this.datosIncidente.nHoras = '';
            this.datosIncidente.tipoIncidente = '';
            this.datosIncidente.trabajadoresAfectados = '';
            this.datosIncidente.pobladoresAfectados = '';
            this.datosIncidente.primerosAuxilios = '';
            this.datosIncidente.fechaIncidente = '';
            this.datosIncidente.horaIncidente = '';
            this.datosIncidente.fechaInvestigacion = '';
            this.datosIncidente.lugarIncidente = '';
            this.datosIncidente.hechos = '';
            this.datosIncidente.declaracionAfectado = null;
            this.datosIncidente.urlDeclaracionAfectado = '';
            this.datosIncidente.nombreDeclaracionAfectado = '';
            this.datosIncidente.declaracionTestigos = null;
            this.datosIncidente.urlDeclaracionTestigos = '';
            this.datosIncidente.nombreDeclaracionTestigos = '';
            this.datosIncidente.procedimientos = null;
            this.datosIncidente.urlProcedimientos = '';
            this.datosIncidente.nombreProcedimientos = '';
            this.datosIncidente.actoSubestandar = '';
            this.datosIncidente.condicionSubestandar = '';
            this.datosIncidente.factoresPersonales = '';
            this.datosIncidente.factoresTrabajo = '';

            this.datosIncidente.listaMedidas = [],

                this.datosIncidente.listaResponsable = [];

        },
        resetModalActualizarIncidente() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarIncidente: function (val) {
            if (val == false) {
                this.resetModalRegistrarIncidente();
            }
        },
        modalActualizarIncidente: function (val) {
            if (val == false) {
                this.resetModalActualizarIncidente();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosIncidente.idCliente = this.datosIncidenteActualizar.idCliente = user.idCliente;
            this.listarProyectos();
            this.listarItinerario();

        }
    }

}
</script>
